<template>
  <section v-if="data">
    <PagesIndexComponent
      :data="data"
      :dataEventEntries="dataEventEntries"
    />
  </section>
  <SharedErrorComponent v-else-if="error" :error="error" />
</template>

<script lang='ts' setup>
useHead({
  htmlAttrs: { 'data-page-type': 'homepage' }
})

// 1
const i18nActiveIso = baseI18nGetActiveIso()

const { data, error } = await useAsyncData(() =>
  fetchQueryPath( queryHomePage( i18nActiveIso ) )
)


basePageSetHead(data)

// 2
const { data: dataEventEntries } = await useAsyncData(() =>
  fetchQueryPath( queryEventEntriesThumbnailUpcoming() )
)
</script>
